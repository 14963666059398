export const convertIntMetacogToString = (value: number): string => {
  switch (value) {
    case 1:
      return "Unaware"
    case 2:
      return "Aware"
    case 3:
      return "Instinctive"
    case 4:
      return "Skillful"
    default:
      return "No data"
  }
}
