const config = {
  apiUrl: process.env.REACT_APP_YAAK_API_URL || '{{ REACT_APP_YAAK_API_URL }}',
  auth: {
    audience:
      process.env.REACT_APP_AUTH_AUDIENCE || '{{ REACT_APP_AUTH_AUDIENCE }}',
    clientId:
      process.env.REACT_APP_AUTH_CLIENT_ID || '{{ REACT_APP_AUTH_CLIENT_ID }}',
    domain: process.env.REACT_APP_AUTH_DOMAIN || '{{ REACT_APP_AUTH_DOMAIN }}',
    scope:
      process.env.REACT_APP_AUTH_SCOPE || 'openid profile email offline_access',
  },
  environment: process.env.REACT_APP_NODE_ENV || '{{ REACT_APP_NODE_ENV }}',
  urls: (locale = 'en') => {
    return {
      privacy: 'https://www.yaak.ai/privacy-customers',
      support: `https://help.yaak.ai/${locale}`,
      terms: 'https://www.yaak.ai/terms-of-service',
    }
  },
  locales: {
    default: 'en',
    supported: ['en', 'de'],
    url: '/lang',
  },
  roles: ['synpli-admin', 'ds-admin', 'ds-instructor', 'ds-student'],
  sentry: {
    dns: process.env.SENTRY_DNS || '{{ SENTRY_DNS }}',
  },
  mapbox: {
    accessToken: process.env.MAPBOX_ACCESS_TOKEN || '{{ MAPBOX_ACCESS_TOKEN }}',
  },
}

export default config
